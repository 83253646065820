<template>
  <v-app>
    <div>
      <div class="loading-container" v-if="jiraLoading">
        <div class="loading-spinner"></div>
      </div>
      <div v-else>
        <div v-if="linkedTickets.length > 0" class="center-float">
          <h2 class="page-title">
            Detail Page for GitLab issue
            <a :href="gitlabTicket.url">{{ gitlabTicket.key }}</a> and Linked
            Tickets
          </h2>
          <BarChart :data="jiraLinkedList" />
          <div>
            <p class="gitlab-title">GitLab Title: {{ gitlabTicket.title }}</p>
          </div>
          <Jira :data="jiraLinkedList" />
        </div>
        <div v-else>
          <h2>
            No Linked Tickets to this GitLab
            <a :href="gitlabTicket.url">{{ gitlabTicket.key }}</a>
          </h2>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EventBus from "../../utils/EventBus";
import BarChart from "../components/BarChart.vue";
import Jira from "../components/TicketManagement/Jira.vue";

export default {
  name: "LinkedTickets",
  components: {
    BarChart,
    Jira,
  },
  computed: {
    ...mapState(["jiraList", "gitlabTicket"]),
  },
  data() {
    return {
      columns: [
        {
          name: "Title",
          key: "title",
          sortable: false,
          clickable: false,
        },
        {
          name: "Number",
          key: "number",
          sortable: false,
          clickable: true,
        },
        {
          name: "Date Created",
          key: "createdDate",
          sortable: true,
          clickable: false,
        },
        {
          name: "Severity",
          key: "severity",
          sortable: true,
          clickable: false,
        },
        {
          name: "Status",
          key: "status",
          sortable: false,
          clickable: false,
        },
        {
          name: "AI Assistant",
          key: "aiAssistant",
          sortable: false,
          clickable: true,
        },
      ],
      jiraLoading: true,
      selectedPage: 1,
      unlinked: false,
      severity: null,
      linkedTickets: [],
      jiraLinkedList: [],
      search: "",
    };
  },
  async mounted() {
    this.ticketId = this.$route.params.id;
    this.setDeploymentName(this.$route.query.platform);
    EventBus.$on("clickTableEvent", this.itemClicked);
    await this.getGitlabById(this.ticketId);
  },
  methods: {
    ...mapActions(["fetchJiraList", "setDeploymentName", "fetchGitlab"]),
    itemClicked(column, item) {
      if (column === "number") {
        window.open(item.url, "_blank");
      }
      if (column === "aiAssistant") {
        window.open(`../jira/${item.key}`, "_blank");
      }
    },
    async getGitlabById(id) {
      this.jiraLoading = true;
      await this.fetchGitlab({ path: `/v1/gitlab/${id}` });
      this.linkedTickets = this.gitlabTicket.linkedTickets || [];
      if (this.linkedTickets.length > 0) {
        await this.getJira();
      }
      this.jiraLoading = false;
    },
    async getJira() {
      const pageSize = 100;
      const totalPages = Math.ceil(this.linkedTickets.length / pageSize);
      const tickets = [];

      for (let page = 1; page <= totalPages; page++) {
        await this.fetchJiraList({
          data: {
            page,
            pageSize,
            projectKey: "NSD",
            keyList: this.linkedTickets,
          },
          path: "/v1/jira/bulk",
        });
        tickets.push(...this.jiraList);
      }
      this.jiraLinkedList = tickets;
    },
  },
};
</script>

<style scoped>
.gitlab-title {
  text-align: left;
  padding-left: 2.2rem;
  font-size: 25px;
}
.page-title {
  color: rgba(42, 41, 41, 0.8);
}
.center-float {
  margin-top: 20px;
}

.radio-toolbar-jira {
  display: flex;
  justify-content: center;
}

.radio-toolbar-jira input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar-jira span {
  float: left;
}

.radio-toolbar-jira i {
  float: left;
  margin: 10px;
  align-self: center;
}

.radio-toolbar-jira .page-label {
  font-size: 12px;
  padding: 5px;
}

.table-container-jira {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}


.center-float-right {
  margin: 20px;
}

.title-bar {
  height: 100px;
}

.suggested-actions {
  text-align: center;
  margin-bottom: 20px;
}

.suggested-actions a {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Add semi-transparent background */
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

#search-card {
  display: flex;
  justify-content: space-between;
}

.ew-tablesearch-input {
  max-width: 250px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
